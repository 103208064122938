<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div v-if="value">
      <div class="d-inline-block">
        <el-tooltip
            :content="getValue(dictionaries[field.dictionary])"
            class="item"
            effect="dark"
            placement="top-start"
        >
          <img
              :alt="getValue(dictionaries[field.dictionary])"
              :src="'data:image/jpeg;base64,' + getAvatar(dictionaries[field.dictionary])"
              class="avatar--ifImage"
              v-if="getAvatar(dictionaries[field.dictionary])"
          >
          <span
              class="avatar--ifNotImage"
              v-else
          >
                            {{ abbreviation(getValue(dictionaries[field.dictionary])) }}
                        </span>
        </el-tooltip>
      </div>
      <div class="d-inline-block">{{ getValue(dictionaries[field.dictionary]) }}</div>
    </div>
    <!--      <div v-else-if="value">-->
    <!--        {{value}}-->
    <!--      </div>-->
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';
import {abbreviation} from '../../../../../helpers/functions';

export default {
  mixins: [abstractForm],
  name: 'FieldUsersIndex',
  components: {BaseFormItem},
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
    getValue(arr) {
      if (Array.isArray(arr)) {
        let current = arr.find(item => item.id === this.value);
        return current && current.name ? current.name : '';
      } else {
        return this.value && this.value.name ? this.value.name : '';
      }
    },
    getAvatar(arr) {
      if (Array.isArray(arr)) {
        let current = arr.find(item => item.id === this.value);
        return current && current.avatar ? current.avatar : '';
      } else {
        // TODO: fix avatar on backend
        return '';
        // return this.value && this.value.avatar ? this.value.avatar : '';
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
